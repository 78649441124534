import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_CONFIG, PARAM } from 'app/configs';
import { ApiService } from './api.service';
import { Visitor } from './../financial-freedom-calculator/shared/visitor.model';

export interface Lead {
  count: number;
  next: string;
  previous: string;
  results: Visitor[];
}

@Injectable({
  providedIn: 'root'
})
export class FinancialFreedomService {
  referralCode: string;

  constructor(private apiService: ApiService) {}

  getFinancialFreedomResultsOnEmail(slug: string, body: Visitor): Observable<Visitor> {
    return this.apiService.patch(API_CONFIG.financialFreedom.specificLead.replace(PARAM, slug), body);
  }

  getReferralCode(): Observable<string> {
    if (this.referralCode) {
      return of(this.referralCode);
    }

    return this.apiService.get(API_CONFIG.financialFreedom.referralCode)
      .pipe(map((r: {referral_code: string }) => {
        this.referralCode = r.referral_code;
        return this.referralCode;
      }));
  }

  getLeads(): Observable<Lead> {
    return this.apiService.get(API_CONFIG.financialFreedom.lead);
  }

  sendVisitorDetails(body: Visitor): Observable<Visitor> {
    return this.apiService.post(API_CONFIG.financialFreedom.lead, body);
  }
}
