import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { API_CONFIG, PARAM } from '../../configs';

@Injectable()
export class OnboardingService {

	private _currentStepIndex: number = 0;

  constructor(private apiService: ApiService) { }

	get currentStepIndex(): number {
		return this._currentStepIndex;
	}

	set currentStepIndex(stepIndex: number) {
		this._currentStepIndex = stepIndex;
	}

  getFinancialConsultant(contactId: string): Observable<any> {
    return this.apiService.get(API_CONFIG.account.financialConsultant.replace(PARAM, contactId));
  }

	updateFinancialConsultant(contactId: string, userData: any): Observable<any> {
    return this.apiService.patch(API_CONFIG.account.financialConsultant.replace(PARAM, contactId), userData);
  }

}