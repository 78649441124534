import { Injectable } from '@angular/core';
import { PARAM, API_CONFIG } from 'app/configs';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from 'app/modules/api';

export interface FilterPeriod {
  start: string;
  end: string;
}

export interface VisitsInfo {
  date: string;
  count: number;
}

export interface VisitsCityInfo {
  city: string;
  country: string,
  count: number;
}

@Injectable()
export class AnalyticsService {
  private _filterPeriod: Subject<FilterPeriod> = new Subject();

  private _tickIntervalMilliseconds = {
    day: 24 * 3600 * 1000,
    week: 7 * 24 * 3600 * 1000,
    month: 30 * 24 * 3600 * 1000,
    year: 365 * 24 * 3600 * 1000
  };

  private _pointIntervalMilliseconds = {
    day: 24 * 3600 * 1000,
    week: 24 * 3600 * 1000,
    month: 24 * 3600 * 1000,
    year: 24 * 3600 * 1000
  };

  get filterPeriod(): Subject<FilterPeriod> {
    return this._filterPeriod;
  }

  constructor(private apiService: ApiService) { }


  getVisitors(startDate: string, endDate: string): Observable<any[]> {
    return this.apiService.get(API_CONFIG.analytics.profileVisitDetails
      .replace(PARAM, startDate) // format 2018-01-01
      .replace(PARAM, endDate) // format 2019-01-25
    ).pipe(
      map((response: { visits: VisitsInfo[] }) => response.visits)
    );
  }

  getVisitorsByCities(startDate: string, endDate: string): Observable<any[]> {
    return this.apiService.get(API_CONFIG.analytics.profileVisitCity
      .replace(PARAM, startDate) // format 2018-01-01
      .replace(PARAM, endDate) // format 2019-01-25
    ).pipe(
      map((response: { visits: VisitsCityInfo[] }) => response.visits)
    );
  }


  getVisitsLast2Weeks(startDate: string, endDate: string): Observable<number> {
    return this.apiService.get(API_CONFIG.analytics.profileVisitsLast2Weeks
      .replace(PARAM, startDate)
      .replace(PARAM, endDate)
    ).pipe(
      map((response: { visit_count: number }) => response.visit_count)
    );
  }

  getCurrentFilterStep({start, end}: FilterPeriod): string {
    const timeDiff = Math.abs((new Date(start)).getTime() - (new Date(end)).getTime());
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

    if (daysDiff < 8) { return 'day'; }
    if (daysDiff < 98) { return 'week'; }
    if (daysDiff < 366) { return 'month'; }
    return 'year';
  }

  // TODO: add type instead any
  getPeriodDataSet(start: string, end: string, dataArray: any[]): number[] {
    const dataSet = [];
    let i = 0;
    const date = new Date(start);
    const endDate = new Date(end);

    while (date < endDate) {
      // TODO: add type instead any
      const dataItem: any = dataArray.find(item => (new Date(item.date)).setHours(0, 0, 0, 0) === date.setHours(0, 0, 0, 0));

      dataSet.push(dataItem ? dataItem.count : 0);

      date.setDate(date.getDate() + 1);
      i++;
    }

    return dataSet;
  }


  getPointInterval(pointInterval: string): number {
    return this._pointIntervalMilliseconds[pointInterval];
  }

  getTickInterval(tickInterval: string): number {
    return this._tickIntervalMilliseconds[tickInterval];
  }
}
